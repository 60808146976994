import React from "react";
import { navigate } from "gatsby-link";
import { Layout } from "../components/index";

import HeroTitle from "../components/HeroTitle";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}
export default class Contact extends React.Component {
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout {...this.props}>
        <div className="page">
          <div className="p-strip is-deep">
            <div className="row">
              <HeroTitle {...this.props} />
              <p>
                If you have any queries whatsoever about me or my services,
                please drop me a line using the form below and I'll get back to
                you as soon as possible.
              </p>
            </div>
            <div className="row">
              <div className="form__wrap">
                <form
                  name="Contact"
                  method="post"
                  encType="application/x-www-form-urlencoded"
                  action="/success/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  <fieldset>
                    <legend>Your details</legend>
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                      <label>
                        Don’t fill this out:{" "}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </p>
                    <p>
                      <label htmlFor="name">Your name:</label>
                      <input
                        type="text"
                        name="name"
                        onChange={this.handleChange}
                        required
                      />
                    </p>
                    <p>
                      <label htmlFor="email">Your email:</label>
                      <input
                        type="email"
                        name="email"
                        onChange={this.handleChange}
                        required
                      />
                    </p>
                    <p>
                      <label htmlFor="phone">Your phone number:</label>
                      <input
                        type="number"
                        name="phone"
                        onChange={this.handleChange}
                        required
                      />
                    </p>
                    <p>
                      <label htmlFor="message">Your message:</label>
                      <textarea
                        name="message"
                        onChange={this.handleChange}
                        rows={4}
                        required
                      />
                    </p>
                    <p className="u-align--right u-no-margin--bottom">
                      <button class="p-button--positive" type="submit">
                        Send message
                      </button>
                    </p>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
